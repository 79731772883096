import { useEffect } from "react";

export const Installed = () => {
  const param = new URLSearchParams(document.location.search);

  let source = param.get("source");
  let creative_id = param.get("creative_id");
  let ad_campaign_id = param.get("ad_campaign_id");
  let offer = param.get("offer");
  let creo_id = param.get("creo_id");
  let external_id = param.get("external_id");
  let site_id = param.get("site_id");
  let cost = param.get("cost");
  let sub_id_1 = param.get("sub_id_1");
  let sub_id_2 = param.get("sub_id_2");
  let sub_id_3 = param.get("sub_id_3");
  let sub_id_4 = param.get("sub_id_4");

  let id = param.get("id");
  let feed_id = param.get("feed_id");
  let pixel = param.get("pixel");
  let fbclid = param.get("fbclid");
  let access_token = param.get("access_token");
  let click_id = param.get("click_id");

  if (!click_id) {
    click_id = localStorage.getItem("click_id");
  }

  if (!pixel) {
    pixel = localStorage.getItem("pixel");
  }

  if (!fbclid) {
    fbclid = localStorage.getItem("fbclid");
  }

  if (!access_token) {
    access_token = localStorage.getItem("access_token");
  }

  if (!id) {
    id = localStorage.getItem("id");
  }

  if (!feed_id) {
    feed_id = localStorage.getItem("feed_id");
  }

  if (!sub_id_1) {
    sub_id_1 = localStorage.getItem("sub_id_1");
  }

  if (!sub_id_2) {
    sub_id_2 = localStorage.getItem("sub_id_2");
  }

  if (!sub_id_3) {
    sub_id_3 = localStorage.getItem("sub_id_3");
  }

  if (!sub_id_4) {
    sub_id_4 = localStorage.getItem("sub_id_4");
  }

  if (!cost) {
    cost = localStorage.getItem("cost");
  }

  if (!creo_id) {
    creo_id = localStorage.getItem("creo_id");
  }

  if (!external_id) {
    external_id = localStorage.getItem("external_id");
  }

  if (!site_id) {
    site_id = localStorage.getItem("site_id");
  }

  if (!source) {
    source = localStorage.getItem("source");
  }

  if (!creative_id) {
    creative_id = localStorage.getItem("creative_id");
  }

  if (!ad_campaign_id) {
    ad_campaign_id = localStorage.getItem("ad_campaign_id");
  }

  if (!offer) {
    offer = localStorage.getItem("offer");
  }

  setTimeout(() => {
    document.getElementById("openApp").click();
  }, 17400);

  useEffect(() => {
    localStorage.removeItem("isClickedInstall");
    try {
      fetch("https://easttwo.top/api/pixel/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          clickid: click_id,
          pixel,
          access_token,
        }),
      });
    } catch (error) {
      console.log(error);
    }
    setTimeout(() => {
      window.location.href =
        `https://arumo.top/MR22Mf?a=b` +
        (sub_id_1 !== null ? `&sub_id_1=${sub_id_1}` : "") +
        (sub_id_2 !== null ? `&sub_id_2=${sub_id_2}` : "") +
        (sub_id_3 !== null ? `&sub_id_3=${sub_id_3}` : "");
    }, 1100);
  }, []);

  return (
    <>
      <div class="loader"></div>
    </>
  );
};
